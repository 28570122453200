import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { useAuth } from '../context/AuthContext';
import { TrendingUp, TrendingDown, Minus } from 'lucide-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

// KST 시간으로 변환하는 함수 추가
const formatKSTDateTime = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const kstDate = new Date(date.getTime() + (9 * 60 * 60 * 1000)); // UTC to KST (+9 hours)

  const year = kstDate.getFullYear();
  const month = String(kstDate.getMonth() + 1).padStart(2, '0');
  const day = String(kstDate.getDate()).padStart(2, '0');
  const hours = String(kstDate.getHours()).padStart(2, '0');
  const minutes = String(kstDate.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

// Rating 변화 표시를 위한 컴포넌트
const RatingChange = ({ delta }) => {
  if (delta > 0) {
    return <span className="text-red-500 text-sm">▲{delta}</span>;
  } else if (delta < 0) {
    return <span className="text-blue-500 text-sm">▼{Math.abs(delta)}</span>;
  }
  return <span className="text-gray-500 text-sm">-</span>;
};

// 모달 컴포넌트 추가
const ExamDetailModal = ({ exam, round, onClose }) => {
  if (!exam) return null;

  // 모달 바깥 영역 클릭 시 닫기
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleBackdropClick}  // 바깥 영역 클릭 이벤트 추가
    >
      <div className="bg-white rounded-lg w-[64%] max-h-[85vh] overflow-hidden shadow-xl">  {/* 너비 축소 */}
        <div className="p-6">
          {/* 요약 정보 - 기존과 동일 */}
          <div className="flex justify-between items-center mb-6">
            <div className="space-y-1">
              <h3 className="text-xl font-bold">시험 상세 정보</h3>
              <div className="text-sm text-gray-600">
                <span className="font-medium">TID: {exam.exam_id}</span>
                <span className="mx-2">·</span>
                <span>{round}회차</span>
              </div>
            </div>
            <div className="text-right space-y-1">
              <div className="text-lg font-bold">Rating: {exam.rating}</div>
              <div className="text-sm">
                <span className="text-red-600">정답: {exam.correct_count}</span>
                <span className="mx-2">·</span>
                <span className="text-blue-600">오답: {exam.wrong_count}</span>
                <span className="mx-2">·</span>
                <span className="text-gray-600">
                  정답률: {((exam.correct_count / (exam.correct_count + exam.wrong_count)) * 100).toFixed(1)}%
                </span>
              </div>

              <div>
                변화량: <RatingChange delta={exam.delta} />
              </div>
            </div>
          </div>

          {/* 문제 상세 테이블 - 결과 칼럼 추가 */}
          <div className="overflow-auto max-h-[calc(80vh-12rem)]">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">번호</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">문제 ID</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">결과</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">난이도</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">문제 Rating</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">기대 정답률</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">Rating</th>
                  <th className="px-4 py-2 text-xs font-medium text-gray-500">변화량</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {exam.sequence.map((problem, index) => (
                  <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="px-4 py-2 text-sm text-center">{index + 1}</td>
                    <td className="px-4 py-2 text-sm text-center">{problem[0]}</td>
                    <td className="px-4 py-2 text-sm text-center">
                      {problem[6] ? (  // 결과(true/false)에 따라 O/X 표시
                        <span className="text-blue-500 font-bold">O</span>
                      ) : (
                        <span className="text-red-500 font-bold">X</span>
                      )}
                    </td>
                    <td className="px-4 py-2 text-sm text-center">{problem[1]}</td>
                    <td className="px-4 py-2 text-sm text-center">{problem[2]}</td>
                    <td className="px-4 py-2 text-sm text-center">{problem[3]}%</td>
                    <td className="px-4 py-2 text-sm text-center">{problem[4]}</td>
                    <td className="px-4 py-2 text-sm text-center">
                      <RatingChange delta={problem[5]} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 닫기 버튼 - 기존과 동일 */}
        <div className="border-t px-6 py-4 bg-gray-50">
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-100 hover:bg-gray-200 
                     text-gray-800 rounded-lg transition-colors"
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

// 마스킹 함수들 추가 (컴포넌트 밖에 선언)
const maskName = (name) => {
  if (!name) return '';
  return name.charAt(0) + '*'.repeat(name.length - 1);
};

const maskEmail = (email) => {
  if (!email) return '';
  const [localPart, domain] = email.split('@');
  return localPart.charAt(0) + '*'.repeat(localPart.length - 1) + '@' + domain;
};

const maskSchoolName = (school) => {
  if (!school) return '';
  return school.charAt(0) + '*'.repeat(school.length - 1);
};

function Datapage() {
  const navigate = useNavigate();
  const { getAccessToken } = useAuth();
  const { stamp_id } = useParams();  // URL 파라미터 추가
  const [rankData, setRankData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedStamp, setSelectedStamp] = useState(null);
  const [selectedExam, setSelectedExam] = useState(null);
  const [selectedRound, setSelectedRound] = useState(null);
  const [isMasked, setIsMasked] = useState(false);

  useEffect(() => {
    const fetchRankData = async () => {
      // 이미 데이터가 있다면 API를 다시 호출하지 않음
      if (rankData) return;

      try {
        const token = await getAccessToken();
        if (!token) {
          navigate('/signin');
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/elotest/rank`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch rank data');
        }

        const data = await response.json();
        setRankData(data.data);
      } catch (error) {
        console.error('Error fetching rank data:', error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRankData();
  }, [getAccessToken, navigate, rankData]); // rankData 의존성 추가

  // 초기 데이터 로드 시 stamp_id가 있으면 해당 학생 선택
  useEffect(() => {
    if (rankData && stamp_id) {
      const stamp = rankData.find(s => String(s.stamp_id) === String(stamp_id));
      if (stamp) {
        setSelectedStamp(stamp);
      }
    }
  }, [rankData, stamp_id]);

  // 검색어 필터링 함수
  const handleSearchChange = (e) => {
    // 영문, 한글(자음/모음 포함), 숫자, 공백만 허용하는 정규식
    const filteredValue = e.target.value.replace(/[^a-zA-Z0-9가-힣ㄱ-ㅎㅏ-ㅣ\s]/g, '');
    setSearchQuery(filteredValue);
  };

  // 필터링된 데이터를 반환하는 memoized 함수
  const filteredRankData = useMemo(() => {
    if (!rankData || !searchQuery) return rankData;

    const query = searchQuery.toLowerCase();
    return rankData.filter(stamp =>
      stamp.student_name.toLowerCase().includes(query) ||
      stamp.school_name.toLowerCase().includes(query) ||
      stamp.student_email.toLowerCase().includes(query) ||
      stamp.edu_ver.toLowerCase().includes(query) ||
      stamp.tree_name.toLowerCase().includes(query)  // tree_name 추가
    );
  }, [rankData, searchQuery]);

  // 카드 클릭 핸들러 수정
  const handleCardClick = (stamp) => {
    setSelectedStamp(stamp);
    navigate(`/data/${stamp.stamp_id}`);  // URL 업데이트
  };

  // 그래프 데이터 가공 수정
  const chartData = useMemo(() => {
    if (!selectedStamp?.exam_list) return [];

    return selectedStamp.exam_list.map((exam, index) => ({
      x: selectedStamp.exam_list.length - index,  // x축: 회차
      rating: exam.rating,                        // y축: rating
      exam_id: exam.exam_id,                     // exam_id로 수정
      delta: exam.delta                          // 변화량
    }));
  }, [selectedStamp]);

  // 테이블 행 클릭 핸들러 추가
  const handleExamClick = (exam, round) => {
    setSelectedExam(exam);
    setSelectedRound(round);
  };

  // stamp_id에 해당하는 요소로 스크롤하는 useEffect 추가
  useEffect(() => {
    if (selectedStamp) {
      const element = document.getElementById(`stamp-${selectedStamp.stamp_id}`);
      if (element) {
        const container = element.parentElement.parentElement; // 스크롤 컨테이너
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // 요소를 컨테이너의 중앙에 위치시키기 위한 스크롤 위치 계산
        const scrollTop = element.offsetTop - container.offsetTop - (containerRect.height - elementRect.height) / 2;

        container.scrollTo({
          top: scrollTop,
          behavior: 'smooth'
        });
      }
    }
  }, [selectedStamp]);

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 bg-gray-100 p-4">
          <div className="flex h-[calc(100vh-7rem)] gap-4">
            {/* 1번 영역 (왼쪽) */}
            <div className="w-[29.4%] bg-white overflow-hidden rounded-lg shadow-lg flex flex-col">
              {/* 헤더 영역 - 고정 */}
              <div className="p-6 bg-white border-b sticky top-0 z-10 space-y-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">Ranking</h3>
                  <div className="flex items-center gap-4">
                    <label className="flex items-center gap-2 text-sm text-gray-600">
                      <input
                        type="checkbox"
                        checked={isMasked}
                        onChange={(e) => setIsMasked(e.target.checked)}
                        className="rounded text-blue-500 focus:ring-blue-500"
                      />
                      개인정보 마스킹
                    </label>
                    <span className="text-sm text-gray-500">
                      Total: {isMasked ? '* / *' : `${filteredRankData?.length || 0} / ${rankData?.length || 0}`}
                    </span>
                  </div>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="이름, 학교, 이메일, 과정, 교재 검색..."
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg
                               focus:outline-none focus:ring-2 focus:ring-blue-500 
                               focus:border-blue-500 text-sm"
                  />
                  {searchQuery && (
                    <button
                      onClick={() => setSearchQuery('')}
                      className="absolute right-3 top-1/2 -translate-y-1/2 
                                 text-gray-400 hover:text-gray-600"
                    >
                      ✕
                    </button>
                  )}
                </div>
              </div>

              {/* 카드 리스트 영역 */}
              <div className="flex-1 overflow-y-auto p-4">
                <div className="space-y-3">
                  {filteredRankData?.map((stamp) => (
                    <div
                      key={stamp.stamp_id}
                      id={`stamp-${stamp.stamp_id}`}  // id 속성 추가
                      onClick={() => handleCardClick(stamp)}
                      className={`bg-white rounded-lg shadow-sm border p-3 hover:shadow-md transition-shadow cursor-pointer
                        ${selectedStamp?.stamp_id === stamp.stamp_id ? 'ring-2 ring-blue-500' : ''}`}
                    >
                      {/* 대표 정보 - 더 컴팩트하게 */}
                      <div className="flex justify-between items-center mb-2">
                        <div>
                          <h4 className="font-semibold">
                            {isMasked ? maskName(stamp.student_name) : stamp.student_name}
                          </h4>
                          <p className="text-gray-600 text-xs">
                            {isMasked ? maskEmail(stamp.student_email) : stamp.student_email}
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <span className="text-xs font-medium bg-blue-100 text-blue-800 px-2 py-0.5 rounded">
                            #{stamp.rank}
                          </span>
                          <div className="text-right">
                            <div className="font-semibold">{stamp.rating}</div>
                            <RatingChange delta={stamp.delta} />
                          </div>
                        </div>
                      </div>

                      {/* 하단 정보 - 한 줄로 정리 */}
                      <div className="flex justify-between items-center text-xs text-gray-600">
                        <div>
                          {isMasked ? maskSchoolName(stamp.school_name) : stamp.school_name} ({stamp.school_grade}학년)
                        </div>
                        <div className="flex gap-2">
                          <span>{stamp.edu_ver}</span>
                          <span>·</span>
                          <span>{stamp.tree_name}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                  {filteredRankData?.length === 0 && (
                    <div className="text-center text-gray-500 py-4">
                      검색 결과가 없습니다
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* 오른쪽 영역 컨테이너 */}
            <div className="w-[70.6%] flex flex-col gap-4">

              {/* 중간 영역: 문제 풀이 현황과 Rating 변화 그래프를 나란히 배치 */}
              <div className="h-[calc((100vh-7rem)*8/17)] flex gap-4">
                {/* 문제 풀이 현황 */}
                <div className="w-1/3 bg-white rounded-lg shadow-lg p-6">
                  <h3 className="text-lg font-semibold mb-4">문제 풀이 현황</h3>
                  {selectedStamp ? (
                    <div className="overflow-auto">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th className="py-2">난이도</th>
                            <th className="py-2 text-red-600">정답</th>
                            <th className="py-2 text-blue-600">오답</th>
                            <th className="py-2 text-gray-600">정답률</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[1, 2, 3, 4, 5].map((level) => {
                            const correct = selectedStamp.stamp_record[0][level - 1];
                            const wrong = selectedStamp.stamp_record[1][level - 1];
                            const total = correct + wrong;
                            const rate = total > 0 ? ((correct / total) * 100).toFixed(1) : '0.0';

                            return (
                              <tr key={level} className="border-t">
                                <td className="py-2 text-center font-medium">Lv.{level}</td>
                                <td className="py-2 text-center text-red-600">{correct}</td>
                                <td className="py-2 text-center text-blue-600">{wrong}</td>
                                <td className="py-2 text-center text-gray-600">{rate}%</td>
                              </tr>
                            );
                          })}
                          {/* 합계 행 추가 */}
                          <tr className="border-t border-t-2">
                            <td className="py-2 text-center font-bold">합계</td>
                            <td className="py-2 text-center text-red-600 font-bold">
                              {selectedStamp.stamp_record[0].reduce((a, b) => a + b, 0)}
                            </td>
                            <td className="py-2 text-center text-blue-600 font-bold">
                              {selectedStamp.stamp_record[1].reduce((a, b) => a + b, 0)}
                            </td>
                            <td className="py-2 text-center text-gray-600 font-bold">
                              {(() => {
                                const totalCorrect = selectedStamp.stamp_record[0].reduce((a, b) => a + b, 0);
                                const totalWrong = selectedStamp.stamp_record[1].reduce((a, b) => a + b, 0);
                                const total = totalCorrect + totalWrong;
                                return total > 0 ? ((totalCorrect / total) * 100).toFixed(1) : '0.0';
                              })()}%
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-[calc(100%-3rem)] text-gray-500">
                      카드를 선택하여 문제 풀이 현황을 확인하세요
                    </div>
                  )}
                </div>

                {/* Rating 변화 그래프 */}
                <div className="w-2/3 bg-white rounded-lg shadow-lg p-6 flex flex-col">
                  <h3 className="text-lg font-semibold">Rating 변화 그래프</h3>
                  {selectedStamp ? (
                    <div className="flex-1 min-h-0">
                      <ResponsiveContainer width="100%" height="100%" aspect={2}>
                        <LineChart
                          data={chartData}
                          margin={{ top: 20, right: 30, left: 40, bottom: 20 }}
                        >
                          <YAxis
                            type="number"
                            domain={['auto', 'auto']}
                            label={{
                              value: 'Rating',
                              angle: -90,
                              position: 'insideLeft',
                              offset: -20,
                              style: {
                                textAnchor: 'middle'
                              }
                            }}
                            axisLine={true}
                            tickLine={true}
                            tick={true}
                            tickMargin={10}
                          />
                          <XAxis
                            dataKey="x"
                            type="number"
                            domain={[1, 'auto']}
                            label={{
                              value: '회차',
                              position: 'bottom',
                              offset: -5
                            }}
                            tickLine={true}
                            tick={true}
                            allowDecimals={false}
                            ticks={[...Array(chartData.length)].map((_, i) => i + 1)}
                          />
                          <Tooltip
                            content={({ payload, label }) => {
                              if (!payload || payload.length === 0) return null; // 데이터가 없으면 null 반환
                              const exam = payload[0].payload; // payload 배열에서 데이터 추출

                              return (
                                <div className="bg-white p-3 border border-gray-300 rounded-lg shadow-lg text-sm">
                                  <div className="font-medium">TID: {exam.exam_id}</div>
                                  <div>Rating: {exam.rating}</div>
                                  <div>변화량: <RatingChange delta={exam.delta} /></div>
                                  <div className="text-gray-500">회차: {exam.x}</div>
                                </div>
                              );
                            }}
                            wrapperStyle={{ zIndex: 10 }}

                          />
                          <Line
                            type="monotone"
                            dataKey="rating"
                            stroke="#8884d8"
                            dot={{ r: 4 }}
                            activeDot={{ r: 6 }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </div>
                  ) : (
                    <div className="flex-1 flex items-center justify-center text-gray-500">
                      카드를 선택하여 그래프를 확인하세요
                    </div>
                  )}
                </div>
              </div>

              {/* 시험 상세 정보 */}
              <div className="h-[calc((100vh-7rem)*9/17)] bg-white rounded-lg shadow-lg p-6 overflow-hidden">
                <h3 className="text-lg font-semibold mb-4">시험 상세 정보</h3>
                {selectedStamp ? (
                  <div className="overflow-auto h-[calc(100%-3rem)]">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500">회차</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500">TID</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500">문제 수</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500">난이도(정답-오답)</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500">Rating</th>
                          <th className="px-3 py-2 text-xs font-medium text-gray-500">시험 일시</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {selectedStamp.exam_list.map((exam, index) => (
                          <tr
                            key={exam.exam_id}
                            className={`${index % 2 === 0 ? 'bg-gray-50' : ''} hover:bg-gray-100 cursor-pointer`}
                            onClick={() => handleExamClick(exam, selectedStamp.exam_list.length - index)}
                          >
                            <td className="px-3 py-2 text-sm text-center">
                              {selectedStamp.exam_list.length - index}
                            </td>
                            <td className="px-3 py-2 text-sm text-center">{exam.exam_id}</td>
                            <td className="px-3 py-2 text-sm text-center">{exam.length}</td>
                            <td className="px-3 py-2 text-sm text-center">
                              {(Math.floor(exam.difficulty / 100 * 100) / 100).toFixed(2)}
                              ({exam.correct_count}-{exam.wrong_count})
                            </td>
                            <td className="px-3 py-2 text-sm text-center">
                              <span className="font-medium">
                                {exam.rating}
                                <span className="font-normal">
                                  (<RatingChange delta={exam.delta} />)
                                </span>
                              </span>
                            </td>
                            <td className="px-3 py-2 text-sm text-center">{formatKSTDateTime(exam.updated_at)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-[calc(100%-3rem)] text-gray-500">
                    카드를 선택하여 상세 정보를 확인하세요
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* 모달 추가 */}
      {selectedExam && (
        <ExamDetailModal
          exam={selectedExam}
          round={selectedRound}
          onClose={() => {
            setSelectedExam(null);
            setSelectedRound(null);
          }}
        />
      )}
    </div>
  );
}

export default Datapage;