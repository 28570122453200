import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ExamListModal from '../components/WrongAnswer/ExamListModal';
import { useAuth } from '../context/AuthContext';

function WrongAnswer() {
  const { student_id, stamp_id } = useParams();
  const { getAccessToken } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [examList, setExamList] = useState([]);
  const [stampInfo, setStampInfo] = useState(null);
  const [wrongAnswerList, setWrongAnswerList] = useState([]);

  const fetchExamList = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/exams/${stamp_id}`,
        {
          headers: { 'Authorization': token }
        }
      );

      if (response.status === 204) {
        setExamList([]);
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setExamList(data.data);
    } catch (error) {
      console.error('시험 목록 조회 오류:', error);
    }
  };

  const fetchStampInfo = async () => {
    try {
      const token = await getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/exams?student_id=${student_id}`,
        {
          headers: { 'Authorization': token }
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const currentStamp = data.data.stamps.find(stamp => stamp.stamp_id === parseInt(stamp_id));
      console.log('Current stamp info:', currentStamp);
      setStampInfo(currentStamp);
    } catch (error) {
      console.error('스탬프 정보 조회 오류:', error);
    }
  };

  useEffect(() => {
    fetchStampInfo();
    fetchExamList();
  }, [stamp_id]);

  useEffect(() => {
    console.log('Current examList:', examList);
    console.log('Current stampInfo:', stampInfo);
  }, [examList, stampInfo]);

  const addWrongAnswerData = (data) => {
    const newEntry = {
      seq: wrongAnswerList.length + 1,
      exam_ids: data.exam_ids,
      wrong_count: data.wrong_count,
      total_count: data.total_count,
      created_at: new Date().toISOString(),
      author: data.author || '관리자'
    };
    setWrongAnswerList(prev => [...prev, newEntry]);
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden">
        <Sidebar />
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
          <div className="container mx-auto px-6 py-8">
            <nav className="mb-6">
              <div className="flex items-center text-sm text-gray-600">
                <Link to="/student" className="hover:text-[#4ABC85]">학생관리</Link>
                <span className="mx-2">&gt;</span>
                <Link to={`/student/${student_id}/stamp`} className="hover:text-[#4ABC85]">교육과정</Link>
                <span className="mx-2">&gt;</span>
                <span className="text-[#4ABC85]">오답노트</span>
              </div>
            </nav>

            <div className="bg-white rounded-lg shadow p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-2xl font-bold text-gray-800">
                  {stampInfo?.tree_name || '교육과정'} 오답노트
                </h2>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 flex items-center gap-2"
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 w-5" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
                    />
                  </svg>
                  오답노트 생성
                </button>
              </div>

              <div className="mt-6">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        EID
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        작성자
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                        시험지리스트
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        문제 수(오답)
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        문제 수
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        생성일
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        관리
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {wrongAnswerList.map((item) => (
                      <tr key={item.seq}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {item.seq}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.author}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.exam_ids.join(', ')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.wrong_count}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {item.total_count}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {new Date(item.created_at).toLocaleDateString('ko-KR')}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium space-x-2">
                          <button
                            onClick={() => {/* TODO: 오답 출력 기능 */}}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            오답출력
                          </button>
                          <button
                            onClick={() => {/* TODO: 문제집 출력 기능 */}}
                            className="text-purple-600 hover:text-purple-900"
                          >
                            문제집출력
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {isModalOpen && (
              <ExamListModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                examList={examList}
                stampId={stamp_id}
                tree_name={stampInfo?.tree_name}
                edu_ver={stampInfo?.edu_ver}
                student_name={stampInfo?.student_name}
                onWrongAnswerGenerated={addWrongAnswerData}
              />
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default WrongAnswer;
