import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { usePrint } from '../../context/PrintContext';

function ExamListModal({ isOpen, onClose, examList, stampId, tree_name, edu_ver, student_name, onWrongAnswerGenerated }) {
  const [selectedExams, setSelectedExams] = useState([]);
  const { getAccessToken } = useAuth();
  const navigate = useNavigate();
  const { setPrintData } = usePrint();
  const [selectAll, setSelectAll] = useState(false);

  if (!isOpen) return null;

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handlePrint = async () => {
    if (selectedExams.length === 0) {
      alert('시험을 선택해주세요.');
      return;
    }

    if (selectedExams.length > 100) {
      alert('시험은 최대 100개까지만 선택 가능합니다.');
      return;
    }

    try {
      // 테이블에 데이터 추가
      onWrongAnswerGenerated({
        exam_ids: selectedExams,
        wrong_count: Math.floor(Math.random() * 20) + 1, // 임시 데이터
        total_count: Math.floor(Math.random() * 50) + 20, // 임시 데이터
      });

      // 모달 닫기
      onClose();

      // 기존 API 호출 부분은 주석 처리 또는 제거
      /*
      const token = await getAccessToken();
      const response = await fetch(...);
      ...
      navigate('/problem-print');
      */
    } catch (error) {
      console.error('오답 수집 중 오류 발생:', error);
      alert('오답 수집 중 오류가 발생했습니다.');
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedExams(examList.map(exam => exam.exam_id));
      setSelectAll(true);
    } else {
      setSelectedExams([]);
      setSelectAll(false);
    }
  };

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={handleBackgroundClick}
    >
      <div 
        className="bg-white p-6 rounded-lg w-3/4 max-h-[80vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">오답노트 생성</h2>
          <div className="flex gap-2">
            <button
              onClick={handlePrint}
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              오답 출력
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 rounded"
            >
              닫기
            </button>
          </div>
        </div>
        
        <div className="mb-4 flex items-center">
          <label className="flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              className="w-5 h-5 mr-2"
            />
            <span className="text-sm font-medium select-none">전체 선택</span>
          </label>
        </div>

        <div className="space-y-4">
          {examList.map((exam) => (
            <div
              key={exam.exam_id}
              className="flex items-center space-x-6 p-4 border rounded hover:bg-gray-50 cursor-pointer"
              onClick={(e) => {
                if (!e.target.matches('input[type="checkbox"]')) {
                  setSelectedExams(prev => {
                    const isSelected = prev.includes(exam.exam_id);
                    const newSelection = isSelected
                      ? prev.filter(id => id !== exam.exam_id)
                      : [...prev, exam.exam_id];
                    setSelectAll(newSelection.length === examList.length);
                    return newSelection;
                  });
                }
              }}
            >
              <div className="px-4">
                <input
                  type="checkbox"
                  checked={selectedExams.includes(exam.exam_id)}
                  onChange={(e) => {
                    setSelectedExams(prev => {
                      const newSelection = e.target.checked 
                        ? [...prev, exam.exam_id]
                        : prev.filter(id => id !== exam.exam_id);
                      setSelectAll(newSelection.length === examList.length);
                      return newSelection;
                    });
                  }}
                  className="w-5 h-5"
                />
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">TID</p>
                <p className="font-semibold">{exam.exam_id}</p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">테스트 상태</p>
                <p className="font-semibold">
                  {exam.exam_status === 200 ? "결과입력필요" : 
                   exam.exam_status === 400 ? "결과입력완료" : 
                   exam.exam_status === 999 ? "결과확정완료" : "상태 미정"}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">테스트 유형</p>
                <p className="font-semibold">{exam.exam_type}</p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">생성일시</p>
                <p className="font-semibold">
                  {new Date(exam.exam_created_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">수정일시</p>
                <p className="font-semibold">
                  {new Date(exam.exam_updated_at).toLocaleDateString()}
                </p>
              </div>
              <div className="flex-1 px-6 py-2 border-r border-gray-200">
                <p className="text-sm text-gray-500 mb-1">문제수</p>
                <p className="font-semibold">{exam.problems_count}</p>
              </div>
              <div className="flex-1 px-6 py-2">
                <p className="text-sm text-gray-500 mb-1">난이도</p>
                <p className="font-semibold">{exam.exam_difficulty}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ExamListModal; 