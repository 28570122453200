import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Dashboard from './pages/Dashboard';
import ProblemRegister from './pages/ProblemRegister';
import TestsetPage from './pages/TestsetPage';
import PreReservationPage from './pages/PreReservationPage';
import ProblemEdit from './pages/ProblemEdit';
import TestRegister from './pages/TestRegister';
import TestResult from './pages/TestResult';
import ProblemPrint from './pages/ProblemPrint';
import TestPapers from './pages/TestPapers';
import StudentManagement from './pages/StudentManagement';
import ProblemSolution from './pages/ProblemSolution';
import SolutionRegister from './pages/SolutionRegister';
import TeacherManagement from './pages/TeacherManagement';
import ManagerManagement from './pages/ManagerManagement';
import ReservationManagement from './pages/ReservationManagement';
import StampManagement from './pages/StampManagement';
import ExamResult from './pages/ExamResult';
import ExamList from './pages/ExamList';
import TextbookPage from './pages/TextbookPage';
import Datapage from './pages/Datapage';
import './App.css';
import { PrintProvider } from './context/PrintContext';
import WrongAnswer from './pages/WrongAnswer';

const ProtectedRoute = ({ children }) => {
  const { getAccessToken, userType } = useAuth();
  const accessToken = getAccessToken();
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/signin" replace />;
  }

  if (userType === 'teacher' &&
    (location.pathname.startsWith('/teachers') ||
      location.pathname.startsWith('/managers'))) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

function App() {
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    const faviconPath = process.env.NODE_ENV === 'production'
      ? '/favicon_prod.ico'
      : '/favicon_dev.ico';

    if (favicon) {
      favicon.href = faviconPath;
    } else {
      const newFavicon = document.createElement('link');
      newFavicon.id = 'favicon';
      newFavicon.rel = 'icon';
      newFavicon.href = faviconPath;
      document.head.appendChild(newFavicon);
    }
  }, []);

  return (
    <Router>
      <PrintProvider>
        <AuthProvider>
          <div className="App">
            <Routes>
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/problem-register"
                element={
                  <ProtectedRoute>
                    <ProblemRegister />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/testset"
                element={
                  <ProtectedRoute>
                    <TestsetPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pre-reservations"
                element={
                  <ProtectedRoute>
                    <PreReservationPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/problem-edit"
                element={
                  <ProtectedRoute>
                    <ProblemEdit />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/problem-edit/:page_number"
                element={
                  <ProtectedRoute>
                    <ProblemEdit />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test-register/:tree_id"
                element={
                  <ProtectedRoute>
                    <TestRegister />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test-result/:exam_id"
                element={
                  <ProtectedRoute>
                    <TestResult />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/problem-print"
                element={
                  <ProtectedRoute>
                    <ProblemPrint />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test-papers"
                element={
                  <ProtectedRoute>
                    <TestPapers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/test-papers/student/:student_id"
                element={
                  <ProtectedRoute>
                    <TestPapers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/student/:page_number?"
                element={
                  <ProtectedRoute>
                    <StudentManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/students/:student_id/test-papers"
                element={
                  <ProtectedRoute>
                    <TestPapers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/problem-solution"
                element={
                  <ProtectedRoute>
                    <Navigate to="/problem-solution/1" replace />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/problem-solution/:page_number"
                element={
                  <ProtectedRoute>
                    <ProblemSolution />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/solution-register"
                element={
                  <ProtectedRoute>
                    <SolutionRegister />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/teachers"
                element={
                  <ProtectedRoute>
                    <TeacherManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/managers"
                element={
                  <ProtectedRoute>
                    <ManagerManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reservations"
                element={
                  <ProtectedRoute>
                    <ReservationManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/student/:student_id/stamp"
                element={
                  <ProtectedRoute>
                    <StampManagement />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/student/:student_id/stamp/:stamp_id/result/:exam_id?"
                element={
                  <ProtectedRoute>
                    <ExamResult />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/student/:student_id/stamp/:stamp_id/wronganswer"
                element={<WrongAnswer />}
              />
              <Route
                path="/exam-list"
                element={
                  <ProtectedRoute>
                    <ExamList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/textbook"
                element={
                  <ProtectedRoute>
                    <TextbookPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/data"
                element={
                  <ProtectedRoute>
                    <Datapage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/data/:stamp_id"
                element={
                  <ProtectedRoute>
                    <Datapage />
                  </ProtectedRoute>
                }
              />
              <Route path="*" element={<Navigate to="/signin" replace />} />
            </Routes>
          </div>
        </AuthProvider>
      </PrintProvider>
    </Router>
  );
}

export default App;
